import React, { Suspense, lazy } from 'react';import ReactDOM from 'react-dom';
import ErrorBoundary from './errorBoundary';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// import { Link } from "react-router-dom";
import TagManager from 'react-gtm-module';
import './i18n';

// import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const AddTrack = lazy(() => import('./addTrack.js'));
const TrackList = lazy(() => import('./trackList.js'));
const EditTrack = lazy(() => import('./editTrack.js'));
const Feedback = lazy(() => import( './feedback.js'));
const History = lazy(() => import( './history.js'));
const Category = lazy(() => import( './category.js'));
const Ranking = lazy(() => import( './ranking.js'));
const Install = lazy(() => import( './install.js'));
const Promotion = lazy(() => import( './promotion.js'));
const PopTool = lazy(() => import( './pop_tool'));
const About = lazy(() => import( './about'));
const Collection = lazy(() => import( './collection'));
const Boradcast = lazy(() => import( './broadcast'));
const Term = lazy(() => import( './term'));
const Privacy = lazy(() => import( './privacy'));
const Product = lazy(() => import( './product'));
const LinkInstall = lazy(() => import( './linkInstall'));
const PromotionTool = lazy(() => import( './promotion_tool'));
const Comparison = lazy(() => import( './comparison'));
const RegisterManual = lazy(() => import( './registerManual'));
const GoogleLogin = lazy(() => import( './GoogleLogin'));
const Prompts = lazy(() => import( './Prompts'));


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBmEOq389-hegaHskm-hDbuMq9fF8F3Fmg',
    authDomain: 'myshopper-be4fa.firebaseapp.com',
    projectId: 'myshopper-be4fa',
    storageBucket: 'myshopper-be4fa.appspot.com',
    messagingSenderId: '149937205708',
    appId: '1:149937205708:web:29c671b2fc9d162bb0ad69',
    measurementId: 'G-54ETMECCCB',
};
const tagManagerArgs = {
    gtmId: 'GTM-WJBL7TST'
}

TagManager.initialize(tagManagerArgs);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Container_idx = () => {
    return (
        <div>
            <header>
                <h1>Track</h1>
            </header>
            <div className="mainContent">
                <div className="container" style={{ opacity: 0 }}>
                    <Link to={'/trackList'}>
                        <button
                            style={{ display: 'block', margin: '20px auto' }}
                        >
                            Go To Track List
                        </button>
                    </Link>
                    <Link to={'/addTrack'}>
                        <button
                            style={{ display: 'block', margin: '20px auto' }}
                        >
                            Go To Add Track
                        </button>
                    </Link>
                    <Link to={'/editTrack'}>
                        <button
                            style={{ display: 'block', margin: '20px auto' }}
                        >
                            Go To Edit Track
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
        <Router>
            <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>            
                <Routes>
                    <Route exact path="/" element={<Ranking />}></Route>
                    <Route exact path="/trackList" element={<TrackList />}></Route>
                    <Route exact path="/addTrack" element={<AddTrack />}></Route>
                    <Route exact path="/editTrack" element={<EditTrack />}></Route>
                    <Route exact path="/feedback" element={<Feedback />}></Route>
                    <Route
                        exact
                        path="/notify_history"
                        element={<History />}
                    ></Route>
                    <Route path="/category/:mainCategory/:subCategory" element={<Category />}></Route>
                    <Route path="/category" element={<Category />}></Route>

                    <Route exact path="/ranking" element={<Ranking />}></Route>
                    <Route exact path="/install" element={<Install />}></Route>
                    <Route exact path="/promotion" element={<Promotion />}></Route>
                    <Route exact path="/pop_tool" element={<PopTool />}></Route>
                    <Route exact path="/about" element={<About />}></Route>
                    <Route exact path="/term" element={<Term />}></Route>
                    <Route exact path="/privacy" element={<Privacy />}></Route>                
                    <Route exact path="/broadcast" element={<Boradcast />}></Route>
                    <Route exact path="/product" element={<Product />}></Route>
                    <Route exact path="/linkInstall" element={<LinkInstall />}></Route>
                    <Route exact path="/promotion_tool" element={<PromotionTool />}></Route>
                    <Route exact path="/comparison" element={<Comparison />}></Route>
                    <Route exact path="/register_manual" element={<RegisterManual />}></Route>
                    <Route exact path="/googleLogin" element={<GoogleLogin />}></Route>

                </Routes>
            </Suspense>
            </ErrorBoundary>
        </Router>,
        rootElement,
    );
} else {
    ReactDOM.render(
        <Router>
            <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>   
                <Routes>
                    <Route exact path="/" element={<Ranking />}></Route>
                    <Route exact path="/trackList" element={<TrackList />}></Route>
                    <Route exact path="/addTrack" element={<AddTrack />}></Route>
                    <Route exact path="/editTrack" element={<EditTrack />}></Route>
                    <Route exact path="/feedback" element={<Feedback />}></Route>
                    <Route
                        exact
                        path="/notify_history"
                        element={<History />}
                    ></Route>
                    <Route path="/category/:mainCategory/:subCategory" element={<Category />}></Route>
                    <Route path="/category" element={<Category />}></Route>
                    <Route exact path="/ranking" element={<Ranking />}></Route>
                    <Route exact path="/install" element={<Install />}></Route>
                    <Route exact path="/promotion" element={<Promotion />}></Route>
                    <Route exact path="/pop_tool" element={<PopTool />}></Route>
                    <Route exact path="/about" element={<About />}></Route>
                    <Route exact path="/term" element={<Term />}></Route>
                    <Route exact path="/privacy" element={<Privacy />}></Route>                
                    <Route exact path="/collection" element={<Collection />}></Route>
                    <Route exact path="/broadcast" element={<Boradcast />}></Route>
                    <Route exact path="/product" element={<Product />}></Route>
                    <Route exact path="/linkInstall" element={<LinkInstall />}></Route>
                    <Route exact path="/promotion_tool" element={<PromotionTool />}></Route>
                    <Route exact path="/comparison" element={<Comparison />}></Route>
                    <Route exact path="/register_manual" element={<RegisterManual />}></Route>
                    <Route exact path="/googleLogin" element={<GoogleLogin />}></Route>
                    <Route exact path="/prompts" element={<Prompts />}></Route>
                </Routes>
            </Suspense>
            </ErrorBoundary>
        </Router>,
        rootElement,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
